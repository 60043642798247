<template>
  <div class="container mt-5 py-4">
    <div class="mt-3" v-if="settings" v-html="settings.site.post_tracking"></div>
    <div class="w-50 mb-4 searchBox">
      <input
        placeholder="نام خود را وارد کنید"
        type="text"
        class="form-control"
        v-model="search"
      />
    </div>
    <b-table
      v-if="search"
      responsive
      :filter-included-fields="['receiver_name']"
      :filter="search"
      :fields="fields"
      :items="items"
    >
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(price)="data">
        {{ data.item.price | price }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";

export default {
  name: "PostTracking",
  components: {
    BTable,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "receiver_name",
          label: "نام گیرنده",
        },
        {
          key: "barcode",
          label: "کد رهگیری پست",
        },
        {
          key: "register_date",
          label: "تاریخ ثبت",
        },
        {
          key: "destination",
          label: "مقصد",
        },
      ],
      search: null,
      listSearch: [],
    };
  },
  mounted() {
  },
  watch: {
    search(newValue) {
      this.listSearch.push(newValue);
      this.searchInExcel(this.listSearch.length);
    },
  },
  methods: {
    searchInExcel(len) {
      setTimeout(() => {
        if (!this.listSearch[len]) {
         this.$axios
            .get(`front/shipping_excels?keyword=${this.listSearch[len-1]}`)
            .then((res) => {
              this.items = res.data.data.shipping_excels;
              this.listSearch=[];
            })
            .catch((error) => {
              alert("مشکلی رخ داد");
            });
        }
      }, 1000);
    },
  },
  computed: {
    settings() {
      const homeData = this.$store.getters["front/getHomeData"];

      return homeData ? homeData.settings : null;
    },
  },
};
</script>

<style scoped>
.searchBox {
    display: flex;
    justify-content: center;
    width: 100% !important;
}
</style>
